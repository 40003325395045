import React from "react";

const Button = ({ onClick, style, button_content }) => (
  <button
    className="btn   btn-secondary  btn-lg shadow-lg "
    onMouseOver={(e) => (e.currentTarget.style.transform = "scale(1.1)")}
    onMouseOut={(e) => (e.currentTarget.style.transform = "")}
    onClick={onClick}
    style={style}
  >
    {button_content}
  </button>
);

export default Button;
