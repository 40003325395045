import { useEffect, useState } from "react";
import axios from "axios";
import CustomComponent from "../General_Content_components/render_custom_html";
import Note from "../General_Content_components/note_component_content";
import Image from "../General_Content_components/image_component_content";
import Links from "../General_Content_components/link_component_content";
import Table from "../General_Content_components/table_component_content";
import "./main_content.css";
import NextandPreviousCard from "../card/next_and_prev/next_and_prev_component";
import CopyURL from "../General_Content_components/copy_url_component";
import CodeSnippet from "../General_Content_components/code_render_component";
import { useParams, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

//import NextandPreviousCard from "../card/next_and_prev/next_and_prev_component";
const MainContent = ({ id, Routes }) => {
  const location = useLocation();
  const param = useParams();
  const [currentpage, setcurrentpage] = useState("");
  const [bookmarkVisibility, setBMV] = useState(true);
  const [bookmarks, setBookmarks] = useState(null);
  const [accepted, setaccepted] = useState(["h3", "p"]);
  const [codeSnips, setCodeSnips] = useState([]);
  const [links, setLinks] = useState([]);
  const [contentBodies, setContentBodies] = useState([]);
  const [notes, setnotes] = useState([]);
  const [tabels, setTables] = useState([]);
  const [images, setImages] = useState([]);
  useEffect(() => {
    const code_fetch_api = async () => {
      await axios
        .get("https://cms.arifpay.org/content-codes")
        .then((res) => {
          const data = res.data;
          setCodeSnips(data);
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
        });
    };
    const fetch_api = async () => {
      await axios
        .get(`https://cms.arifpay.org/content-bodies?Parent_id=${id}`)
        .then((res) => {
          const data = res.data;
          setContentBodies(data);
          setBookmarks(
            contentBodies.filter((content) => content.Body_type === "h3")
          );
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
        });
    };
    const Notes_fetch_api = async () => {
      await axios
        .get("https://cms.arifpay.org/notes")
        .then((res) => {
          const data = res.data;
          setnotes(data);
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
        });
    };
    const links_fetch_api = async () => {
      await axios
        .get("https://cms.arifpay.org/links")
        .then((res) => {
          const data = res.data;
          setLinks(data);
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
        });
    };

    const image_fetch_api = async () => {
      await axios
        .get("https://cms.arifpay.org/content-body-imgs")
        .then((res) => {
          const data = res.data;
          setImages(data);
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
        });
    };
    fetch_api();
    image_fetch_api();
    Notes_fetch_api();
    links_fetch_api();
    code_fetch_api();
    setcurrentpage(location.pathname);
  }, [contentBodies]);
  return (
    <div className="Main_content_handler row p-2 ">
      <div className="col  position-relative">
        {contentBodies[0] &&
          contentBodies.map(
            (page, idx) =>
              (accepted.includes(page.Body_type.toString()) && (
                <CustomComponent key={idx} Tag={page.Body_type} id={page.Body}>
                  {page.Body}
                </CustomComponent>
              )) ||
              (page.Body_type === "note" && (
                <Note key={idx} id={Number(page.Body)} data={notes} />
              )) ||
              (page.Body_type === "img" && (
                <Image key={idx} data={images} id={Number(page.Body)} />
              )) ||
              (page.Body_type === "a" && (
                <Links key={idx} data={links} id={Number(page.Body)} />
              )) ||
              (page.Body_type === "table" && (
                <Table key={idx} group_id={Number(page.Body)} />
              )) ||
              (page.Body_type === "url" && (
                <CopyURL key={idx} url={page.Body} />
              )) ||
              (page.Body_type === "code" && (
                <CodeSnippet
                  key={idx}
                  id={Number(page.Body)}
                  data={codeSnips}
                />
              ))
          )}
        {/* < false to true   */}
        {!bookmarkVisibility && (
          <div className="position-absolute top-0 end-0">
            <button type="button" className="btn " onClick={() => setBMV(true)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-arrow-bar-left"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5"
                />
              </svg>
            </button>
          </div>
        )}

        {/* > tru to false */}
        {bookmarkVisibility && (
          <div className="position-absolute top-0 end-0">
            <button
              type="button"
              className="btn "
              onClick={() => setBMV(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                fill="currentColor"
                class="bi bi-arrow-bar-right"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M6 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L12.293 7.5H6.5A.5.5 0 0 0 6 8m-2.5 7a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5"
                />
              </svg>
            </button>
          </div>
        )}
      </div>
      {bookmarkVisibility && (
        <div className="col-3 ">
          {bookmarks &&
            bookmarks.map((bookmark, idx) => {
              return (
                <p className="B-links">
                  <HashLink
                    key={idx}
                    to={`${location.pathname}#${bookmark.Body}`}
                  >
                    {bookmark.Body}
                  </HashLink>
                </p>
              );
            })}
        </div>
      )}
      {/* 
      <div className="bookmarks">
        {bookmarks &&
          bookmarks.map((bookmark, idx) => {
            return (
              <p className="B-links">
                <HashLink
                  key={idx}
                  to={`${location.pathname}#${bookmark.Body}`}
                >
                  {bookmark.Body}
                </HashLink>
              </p>
            );
          })}
      </div> */}
      <NextandPreviousCard currPage={id} />
    </div>
  );
};

export default MainContent;
