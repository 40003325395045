import { useMemo } from "react";

const CodeSnippet = ({id,data}) => {

  const memoizedData = useMemo(() => {
    if (!data) {
      return null;
    }

    return data.filter(code=>code.id==id)
  }, [data]);

  if (!memoizedData) {
    return null;
  }
  return (
    <pre style={{ backgroundColor: "#f5f5f5", padding: "10px" }}>
        { memoizedData.map((code,idx)=><code  style={{ fontFamily: "monospace" }} key={idx}>{code.Code_snippets}</code>)
    }
    </pre>
  );
};

export default CodeSnippet;
