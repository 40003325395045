import React, { useState, useEffect } from "react";
import { ListGroup } from "react-bootstrap";
import ReactPlayer from "react-player";
import "./howto.css";

const HowTo = () => {
  const [videos, setVideos] = useState([]);
  const [activeVideo, setActiveVideo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://cms.arifpay.org/how-tos");
        const data = await response.json();
        setVideos(data);
        setActiveVideo(data[0]);
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container-fluid main">
      <div className="row">
        <div className="col-lg-3 col-md-4 col-sm-12">
          <ListGroup>
            {videos.map((video) => (
              <ListGroup.Item
                key={video.id}
                action
                onClick={() => setActiveVideo(video)}
                className={activeVideo.id === video.id ? "active m-2 text-center" : "text-center"}
              >
                {video.Title}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>
        <div className="col-lg-9 col-md-8 col-sm-12 video-container pb-3">
          <h1 className="text-center">{activeVideo.header}</h1>
          <hr />
          <div className="video-section">
            <ReactPlayer
              url={activeVideo.url}
              width="100%"
              height="500px"
              controls={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowTo;
