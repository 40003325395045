import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import './css_general_content/link_component.css'

const Links=({data,id})=>
{
    const param=useParams();
    const [specificLink,setSpecificLink]= useState(null);
   
    useEffect(()=>{
        setSpecificLink(data.filter(link=>link.id==id));
    },[data])
    return(
        <>
        <div className="linkColor">
        { specificLink &&        
            specificLink.map((link,idx)=><a key={idx} href={link.Url}>{link.Name}</a>)
        }
        </div>
        </>
    )
}
export default Links;