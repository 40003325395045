import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
const uniqueID = () => '_' + Math.random().toString(36).substr(2, 9);
const Table=({group_id})=>{
    const param=useParams();
    const[tables,setTables]=useState([])
    useEffect(()=>{
        const table_fetch_api = async () => {
            await axios
              .get(`https://cms.arifpay.org/content-body-tables?Group_id=${group_id}`)
              .then((res) => {
                const data = res.data;
                setTables(data);
              })
              .catch((error) => {
                console.error("Error fetching data: ", error);
              });
          };
          table_fetch_api();
    },[group_id])
    return(
        <>
        { tables[0]  &&
       <table >
        <thead>        
            <tr>
                {tables[0].Col_one!=null?<th>{tables[0].Col_one}</th>:console.log()}
                {tables[0].Col_two!=null?<th>{tables[0].Col_two}</th>:console.log()}
                {tables[0].Col_three!=null?<th>{tables[0].Col_three}</th>:console.log()}
                {tables[0].Col_four!=null?<th>{tables[0].Col_four}</th>:console.log()}
            </tr>
        </thead>
        <tbody>
       {tables.slice(1).map((data,idx)=>{
        return(
            <tr key={idx}>
            {data.Col_one!=null?<td >{data.Col_one}</td>:console.log()}
            {data.Col_two!=null?<td  >{data.Col_two}</td>:console.log()}
            {data.Col_three!=null?<td  >{data.Col_three}</td>:console.log()}
            {data.Col_four!=null?<td >{data.Col_four}</td>:console.log()}
            </tr>
        )
       })}
       </tbody>
       </table>
    }
    </>
    )
}
export default Table;