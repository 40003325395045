import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from 'axios'
import './next_and_prev.css'
const NextandPreviousCard = ({ currPage}) => {
  const param=useParams();
  const navigate=useNavigate();
  const[pages, setPages]=useState([]);
  const[routes,setRoutes]=useState(null);
  const [next,setNext]=useState(null);
  const [prev,setPrev]=useState(null);
  useEffect(()=>{
    const fetch_api = async () => {
      await axios
        .get("https://cms.arifpay.org/content-pages")
        .then((res) => {
          const data = res.data;
          setPages(data.sort((page,next)=>Number(page.Parent_id)<Number(next.Parent_id)));
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
        });
    };
    const routes_fetch_api = async () => {
      await axios
        .get("https://cms.arifpay.org/developer-portal-routes")
        .then((res) => {
          const data = res.data;
          setRoutes(data);
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
        });
    };
    routes_fetch_api();
    fetch_api();
    pages!=null?setNext(pages.filter(page=>Number(page.id)===(Number(currPage)+1))):console.log()
    pages!=null?setPrev(pages.filter(page=>Number(page.id)===(Number(currPage)-1))):console.log()
    
  },[param])
  const handel_click=(card_name)=>
  {
    navigate(routes.filter(route=>route.Name===card_name)[0].Url);
  }
  return (
    <>{pages[0] &&
    <div className="btn-container" style={{ marginTop: "90px" }}>
      
     { (prev[0]!=null && routes!=null)? <div onClick={()=>handel_click(prev[0].Name)}
        className={`button1 ${Number(currPage) === 1 ? "disabled" : ""}`}>
        Previous<br/>{"<< "+prev[0].Name}
      </div>:console.log()}

      {(next[0]!=null && routes!=null)?<div onClick={()=>handel_click(next[0].Name)}
        className={`button2 ${currPage === pages.length - 1 ? "disabled" : ""}`}>
        Next<br/>
        {next[0].Name+" >>"}
      </div>:console.log()}
     
    </div>
    }
    </>
  );
};

export default NextandPreviousCard;