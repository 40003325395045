import NavBar from "./components/Nav-bar/nav-bar";
import Footer from "./components/footer/footer";
import { Outlet } from "react-router-dom";

import SideBar from "./components/sidebar/SideBar";
import { Routes, Route } from "react-router-dom";
import HowTo from "./components/howto/howto";
import HomePage from "./components/Homepage/HomePage";

import { useEffect, useState } from "react";
import axios from "axios";
import "../src/app.css";
import MainContent from "./components/Main content page/general_main_content_component";
import { useParams } from "react-router-dom";
function App() {
  const Param = useParams();
  const baseurl = "https://cms.arifpay.org";
  const [nav_response, setnNavresponse] = useState(null);
  const [footer_response, setnFooterresponse] = useState([]);
  const [SDk_response, setnSDKresponse] = useState([]);
  const [routes_and_contents, setroutesContents] = useState(null);
  const [routes, setRoutes] = useState(null);
  useEffect(() => {
    const SDK_fetch_api = async () => {
      await axios
        .get("https://cms.arifpay.org/sdk-and-plugins")
        .then((res) => {
          const data = res.data;
          setnSDKresponse(data);
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
        });
    };
    const routes_fetch_api = async () => {
      await axios
        .get("https://cms.arifpay.org/developer-portal-routes")
        .then((res) => {
          const data = res.data;
          setRoutes(data);
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
        });
    };
    const fetch_parent_api = async () => {
      await axios
        .get("https://cms.arifpay.org/content-pages")
        .then((res) => {
          const data = res.data;
          setroutesContents(data.filter((page) => page.Parent_id != 0));
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
        });
    };
    const nav_fetch_api = async () => {
      await axios
        .get("https://cms.arifpay.org/nav-bars")
        .then((res) => {
          const data = res.data;
          setnNavresponse(data);
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
        });
    };
    const footer_fetch_api = async () => {
      await axios
        .get("https://cms.arifpay.org/footers")
        .then((res) => {
          const data = res.data;
          setnFooterresponse(data);
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
        });
    };
    SDK_fetch_api();
    nav_fetch_api();
    footer_fetch_api();
    fetch_parent_api();
    routes_fetch_api();
  }, []);
  return (
    <div className="App">
      {nav_response && (
        <NavBar
          url={baseurl + nav_response[0].Logo.url}
          data={nav_response}
          sdk={SDk_response}
          Routes_list={routes}
        />
      )}

      {routes_and_contents && (
        <Routes>
          <Route index element={<HomePage />} />
          <Route element={<SidebarLayout style={{ flex: "0 0 200px" }} />}>
            {routes_and_contents.map((routes, idx) => (
              <Route
                key={idx}
                path={"/" + routes.Name.toLowerCase().replace(/\s+/g, "")}
                element={<MainContent id={routes.id} />}
              />
            ))}

            <Route path="/overview" element={<MainContent id={1} />} />
            <Route path="/errorcodes" element={<MainContent id={21} />} />
          </Route>
          <Route path={"/howto"} element={<HowTo />} />
        </Routes>
      )}
      <Footer data={footer_response} Routes_list={routes} />
    </div>
  );
  function SidebarLayout() {
    return (
      <div className="sidebar-container">
        <SideBar Routes_list={routes} />

        <div className="container-fluid p-3 ">
          <Outlet />
        </div>
      </div>
    );
  }
}
export default App;
