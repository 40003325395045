import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./SideBar.css";

import { MDBAccordion, MDBAccordionItem } from "mdb-react-ui-kit";

export default function Collapsed({ Routes_list }) {
  const removedPlugins = ["Java", "Nest", "Dot Net", "Woo commerce", "Kotlin"];

  const [itemStates, setItemStates] = useState(0);
  const [subItemStates, setSubItemStates] = useState(0);
  const [items, setItems] = useState([]);
  const [allData, setAllData] = useState([]);
  const [filteredDataArray, setFilteredDataArray] = useState([]);
  const [subFilteredDataArray, setSubFilteredDataArray] = useState([]);
  const navigate = useNavigate();
  const navigateParent = useNavigate();
  const navigateInnerChild = useNavigate();
  useEffect(() => {
    const filter = (list) => {
      const itemLabels = [];

      for (let val of list) {
        if (val.Parent_id === "0") {
          itemLabels.push(val);
        }
      }
      return itemLabels;
    };

    const fetchData = async () => {
      try {
        const response = await fetch("https://cms.arifpay.org/content-pages");
        const data = await response.json();
        setAllData(
          data.filter((datas) => !removedPlugins.includes(datas.Name))
        );
        setItems(filter(data));
        setItemStates(0);
        setSubItemStates(0);

        setFilteredDataArray(Array(data.length).fill([]));

        setSubFilteredDataArray(Array(data.length).fill([]));
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };
    fetchData();
  }, []);

  const handleItemClick = (index, id, name) => {
    navigateParent(Routes_list.filter((route) => route.Name === name)[0].Url);

    let idStr = id.toString();
    const filteredData = allData.filter((item) => item.Parent_id === idStr);
    setFilteredDataArray((prevArray) => {
      const newArray = [...prevArray];
      newArray[index] = filteredData;
      return newArray;
    });
  };
  const HandleSubItemClick = (index, id, name, e) => {
    e.stopPropagation();

    navigate(Routes_list.filter((route) => route.Name === name)[0].Url);
    let idStr = id.toString();
    const filteredData = allData.filter((item) => item.Parent_id === idStr);

    setSubFilteredDataArray((prevArray) => {
      const newArray = [...prevArray];
      newArray[index] = filteredData;
      return newArray;
    });
  };
  const Handel_inner_most_child = (name, e) => {
    e.stopPropagation();
    navigateInnerChild(
      Routes_list.filter((route) => route.Name === name)[0].Url
    );
  };
  return (
    <>
      <link
        href="https://use.fontawesome.com/releases/v5.15.1/css/all.css"
        rel="stylesheet"
      />
      <div className="sidebar-wrapper p-1">
        <MDBAccordion
          initialActive={itemStates}
          style={{ marginTop: "0px", marginBottom: "0px" }}
          className="mdbAccordionsidebar "
        >
          {items.map((label, index) => {
            return (
              <MDBAccordionItem
                key={index}
                collapseId={label.id}
                headerTitle={label.Name}
                onClick={() => handleItemClick(index, label.id, label.Name)}
                className="mdbItem "
              >
                <MDBAccordion
                  initialActive={itemStates}
                  className="mdbiconchild"
                >
                  {filteredDataArray[index].map((item, index) => {
                    return (
                      <MDBAccordionItem
                        collapseId={item.id}
                        key={index}
                        headerTitle={item.Name}
                        onClick={(event) => {
                          HandleSubItemClick(index, item.id, item.Name, event);
                        }}
                        className="mdbItem"
                      >
                        <MDBAccordion
                          initialActive={subItemStates}
                          className="mdbicon"
                        >
                          {subFilteredDataArray[index].map((element) => {
                            return (
                              <MDBAccordionItem
                                key={index}
                                collapseId={element.id}
                                headerTitle={element.Name}
                                className="mdbItem no-children"
                                onClick={(e) =>
                                  Handel_inner_most_child(element.Name, e)
                                }
                              ></MDBAccordionItem>
                            );
                          })}
                        </MDBAccordion>
                      </MDBAccordionItem>
                    );
                  })}
                </MDBAccordion>
              </MDBAccordionItem>
            );
          })}
        </MDBAccordion>
      </div>
    </>
  );
}
