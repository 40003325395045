import { useRef } from "react";
import './css_general_content/copy.css'
const CopyURL = ({ url }) => {
  return (
    <div className="copy">
      {url}
    </div>
  );
};

export default CopyURL;
