import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBContainer,
} from "mdb-react-ui-kit";
import ReactPlayer from "react-player";
function getVideoURLs(...urls) {
  return urls.filter(Boolean).map((url) => url.replace(/\s|\n|\t/g, ""));
}

const ContentCard = ({ content, index }) => (
  <MDBCard style={{ border: "none", boxShadow: "none" }}>
    <MDBContainer>
      <div style={{ width: "100%", height: "500px", overflow: "hidden" }}>
        <ReactPlayer
          playing
          loop
          muted
          width="100%"
          height="100%"
          url={getVideoURLs(content.Video1URL, content.Video2URL)}
        />
      </div>
    </MDBContainer>
    <MDBCardBody>
      <MDBCardTitle className="h3 text-center">
        {content.Title}

        {/* <h1>{getVideoURLs(content.Video1URL, content.Video2URL)}</h1> */}
      </MDBCardTitle>
      <MDBCardText>{content.Descriptions}</MDBCardText>
    </MDBCardBody>
  </MDBCard>
);

export default ContentCard;
